<template>
  <section class="notes">
    <!-- Header -->
    <div class="notes-header">
      <v-row class="align-center pb-2">
        <v-col cols="6" class="pb-0">
          <h1>Notes</h1>
        </v-col>
        <v-col cols="6" class="d-flex justify-end">
          <v-dialog v-model="isCreateDialogVisible" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn elevation="0" color="primary" tile v-bind="attrs" v-on="on">Add Note</v-btn>
            </template>

            <v-card>
              <v-card-text class="pa-0">
                <v-textarea
                  background-color="#ffe0b0"
                  dense
                  solo
                  no-resize
                  placeholder="Add Note"
                  v-model="note"
                  hide-details=""
                  class="notes-textarea">
                </v-textarea>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-btn elevation="0" :disabled="note === ''" color="primary" tile @click="createNoteHandler()">Add</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </div>

    <!-- Notes not found -->
    <div v-if="!isLoading && totalNotes == 0" class="mt-8">
      <h3>Notes not found. Please add one.</h3>
    </div>

    <!-- Notes -->
    <div v-else class="mt-2">
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="3" v-for="note in notes" :key="note.id" :class="{ 'not-editable': !note.showActions }">
              <div class="note-container">
                <div class="ripple-loading" v-if="note.isLoading">
                  <div></div>
                  <div></div>
                </div>
                <div class="notes-body">
                  <v-textarea
                    @blur="updateNoteHandler(note)"
                    @focus="captureCurrentNote(note)"
                    background-color="transparent"
                    dense
                    solo
                    no-resize
                    placeholder="Add Note"
                    v-model="note.notes"
                    hide-details=""
                    spellcheck="false"
                    class="notes-textarea">
                  </v-textarea>
                </div>
                <div class="notes-footer d-flex">
                  <div>
                    <div class="text-capitalize">{{ note.user.name }}</div>
                    <div>{{ note.updated_at | humanReadableDate }}</div>
                  </div>
                  <v-btn v-if="note.showActions" icon x-small elevation="0" color="error" @click="deleteNoteHandler(note)">
                    <v-icon dark>remove_circle</v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <!-- Notification -->
    <v-snackbar v-model="isSnackbarVisible" :color="snackBarType" :timeout="timeout" elevation="0" top tile>
      {{ snackbarText }}
    </v-snackbar>
  </section>
</template>

<script>
  import { createHelpers } from 'vuex-map-fields';
  import { FETCH_CURRENT_SHOP } from "@/store/actions.type";
  import { mapState, mapGetters } from "vuex";
  const { mapFields } = createHelpers({
    getterType: 'notes/getField',
    mutationType: 'notes/updateField',
  });

  export default {
    name: 'Notes',

    data() {
      return {
        capturedText: '',
        isCreateDialogVisible: false,
        isSnackbarVisible: false,
        note: '',
        snackbarText: '',
        snackBarType: '',
        timeout: 1000
      }
    },

    async mounted() {
      this.currentShopId = this.$route.params.id;
      if (this.currentShop === null) {
        await this.$store.dispatch(`shops/${FETCH_CURRENT_SHOP}`, this.currentShopId);
      }
      this.fetchNotes();
    },

    beforeRouteLeave(to, from, next) {
      this.notes = [];
      next();
    },

    computed: {
      ...mapState("auth", ["admin"]),
      ...mapGetters("shops", ["currentShop"]),
      ...mapFields([
        'isLoading',
        'notes',
        'responseMessage'
      ]),

      totalNotes() {
        return this.notes.length;
      },
    },

    watch: {
      'notes'() {
        this.showNotesAction(this.notes);
      }
    },

    methods: {
      captureCurrentNote(note) {
        this.capturedText = note.notes.trim();
      },

      async fetchNotes() {
        await this.$store.dispatch('notes/fetchNotes', this.currentShop.id);
        this.showNotesAction(this.notes);
      },

      showNotesAction(notes) {
        notes.forEach(note => {
          if(+note.user_id === +this.admin.id) {
            note.showActions = true;
          }
        })
      },

      async createNoteHandler() {
        await this.$store.dispatch('notes/createNote', {
          "notes": this.note,
          "storeId": this.currentShop.id,
          "loginUser": +this.admin.id
        });
        this.fetchNotesAndShowSnackbar(this.responseMessage);
        this.note = '';
        this.isCreateDialogVisible = false;
      },

      async deleteNoteHandler(note) {
        note.isLoading = true;
        await this.$store.dispatch('notes/deleteNote', {
          noteId: note.id
        });
        this.fetchNotesAndShowSnackbar(this.responseMessage);
      },

      async updateNoteHandler(note) {
        if(this.capturedText === note.notes.trim()) { return; }
        note.isLoading = true;
        if(note.notes === '') {
          this.deleteNoteHandler(note);
          return;
        }

        await this.$store.dispatch('notes/updateNote', {
          noteId: note.id,
          notes: note.notes
        });
        this.fetchNotesAndShowSnackbar(this.responseMessage);
      },

      async fetchNotesAndShowSnackbar(response) {
        await this.fetchNotes();
        this.snackbarText = response.message;
        this.snackBarType = response.type;
        this.isSnackbarVisible = true;
      }
    }
  }
</script>

<style lang="scss">
  .note-container {
    position: relative;
  }

  .notes-textarea {
    .v-input__slot {
      padding: .75rem !important;
      box-shadow: none !important;
      border: 0 !important;
      border-radius: 0 !important;
      font-size: 13px !important;
      font-weight: 400 !important;
      height: max-content;
    }

    textarea {
      margin: 0 !important;
      width: 100%;
      line-height: 22px !important;
    }
  }

  .notes-body {
    background: #ffe0b0;
    border-radius: 15px 15px 0 0 ;
    min-height: 125px;
    border: 1px solid #ffe0b0;
  }

  .notes-footer {
    align-items: flex-end;
    background: #ffe0b0;
    border-radius: 0 0 15px 15px;
    color: #623f06;
    font-size: 12px;
    font-weight: 600;
    justify-content: space-between;
    padding: .5rem .75rem;
  }

  .not-editable {
    pointer-events: none !important;
  }

  .notes {
    h1 {
      font-size: 2rem;
    }

    .v-input--is-focused {
      .v-input__slot {
        background: #fefefe !important;
        border-radius: 15px !important;
      }
    }

    .v-snack__content {
      font-size: 15px !important;
      text-align: center !important;
    }

    .v-btn {
      font-weight: bold !important;
    }
  }
</style>
